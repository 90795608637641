import { Config } from '../../../config/api';
import { authHeader, handleErrors } from '../../../utils/API';

import { ExportMainEntityEnum, TaxonomyTypeSlug } from '@ternala/voltore-types/lib/constants';

import {
  EnterpriseGetListRequest, ExportFileDTO,
  PersonCreateResponse,
  PersonDeleteRequest,
  PersonDeleteResponse,
  PersonGetListRequest,
  PersonGetListResponse,
  PersonGetRequest,
  PersonGetResponse,
  PersonUpdateResponse, PropertyGetListRequest,
  PropertyOwnedDTO
} from '@ternala/voltore-types';
import { appendSearchParams } from 'utils/appendSearchParams';
import {
  PersonCreateRequestExpanded,
  PersonUpdateRequestExpanded
} from '../models';
import { AsyncOptions } from 'components/UI/controls/SelectSearchCustom';
import { IError } from '../../model';
import { PropertyApi } from '../../property/transport/property.api';
import { EnterpriseApi } from '../../enterprise/transport/enterprise.api';
import { format } from 'date-fns';
import { isNull } from 'lodash';

class API {
  public prepareGetListLink(url: URL, data: PersonGetListRequest) {
    if (data.hasOwnProperty('selectedTags')) {
      data.selectedTags?.forEach((tag) => {
        url.searchParams.append('selectedTags[]', `${tag}`);
      });
    }

    if (data.hasOwnProperty('unselectedTags')) {
      data.unselectedTags?.forEach((tag) => {
        url.searchParams.append('unselectedTags[]', `${tag}`);
      });
    }

    if (Array.isArray(data[TaxonomyTypeSlug.involvementType])) {
      data[TaxonomyTypeSlug.involvementType]?.forEach((item) => {
        url.searchParams.append(
          `${TaxonomyTypeSlug.involvementType}[]`,
          String(item)
        );
      });
    }

    if (Array.isArray(data.enterprise)) {
      data.enterprise?.forEach((item) => {
        url.searchParams.append(`enterprise[]`, String(item));
      });
    }

    if (data.hasOwnProperty('owner'))
      url.searchParams.append('owner', String(data.owner));
    if (data.hasOwnProperty('decisionMaker'))
      url.searchParams.append(
        'decisionMaker',
        String(data.decisionMaker)
      );

    return url;
  }

  public async getPersons(
    getPersonData: PersonGetListRequest,
    token: string
  ): Promise<PersonGetListResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'person/list');

    url = appendSearchParams(url, getPersonData);

    url = this.prepareGetListLink(url, getPersonData);

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token)
        }
      })
    );
  }

  public async getOwnedProperties(
    getOwnedPropertiesData: { id: number },
    token: string
  ): Promise<PropertyOwnedDTO[] | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'person/owned-properties');

    url.searchParams.append('id', String(getOwnedPropertiesData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token)
        }
      })
    );
  }

  public async createPerson(
    createPersonData: PersonCreateRequestExpanded,
    token: string
  ): Promise<PersonCreateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'person/create');
    const data = new FormData();

    Object.entries(createPersonData).forEach(([key, val]) => {
      if(val === undefined || isNull(val)) return;
      if (key === 'mailingAddress') {
        for (let key1 in val) {
          data.append(`${key}[${key1}]`, val[key1]);
        }
        return;
      }

      if (key === 'phones') {
        val.map((item: any) => {
          return data.append(`phones[]`, JSON.stringify(item));
        });
        return;
      }

      if (key === 'emails') {
        val.map((item: any) => {
          return data.append(`emails[]`, JSON.stringify(item));
        });
        return;
      }

      if (Array.isArray(val)) {
        val.forEach((singleValue) => {
          data.append(`${key}[]`, singleValue);
        });

        return;
      }

      return data.append(key, val);
    });

    return handleErrors(
      fetch(url.toString(), {
        method: 'POST',
        headers: {
          ...authHeader(token)
        },
        body: data
      })
    );
  }

  public async getPerson(
    getPersonData: PersonGetRequest,
    token: string
  ): Promise<PersonGetResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'person/get');

    url.searchParams.append('id', String(getPersonData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'GET',
        headers: {
          ...authHeader(token)
        }
      })
    );
  }

  public async updatePerson(
    updatePersonData: PersonUpdateRequestExpanded,
    token: string
  ): Promise<PersonUpdateResponse | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'person/update');

    const data = new FormData();

    Object.entries(updatePersonData).forEach(([key, val]) => {
      if(val === undefined || isNull(val)) return;

      if (key === 'mailingAddress') {
        for (let key1 in val) {
          if (val[key1]) {
            data.append(`${key}[${key1}]`, val[key1]);
          }
        }
        return;
      }

      if (key === 'phones') {
        val.map((item: any) => {
          return data.append(`phones[]`, JSON.stringify(item));
        });
        return;
      }

      if (key === 'emails') {
        val.map((item: any) => {
          return data.append(`emails[]`, JSON.stringify(item));
        });
        return;
      }

      if (Array.isArray(val)) {
        val.forEach((singleValue) => {
          data.append(`${key}[]`, singleValue);
        });
        return;
      }

      return data.append(key, val);
    });

    return handleErrors(
      fetch(url.toString(), {
        method: 'PUT',
        headers: {
          ...authHeader(token)
        },
        body: data
      })
    );
  }

  public async deletePerson(
    deletePersonData: PersonDeleteRequest,
    token: string
  ): Promise<PersonDeleteResponse | string> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'person/delete');

    url.searchParams.append('id', String(deletePersonData.id));

    return handleErrors(
      fetch(url.toString(), {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token)
        }
      })
    );
  }

  public async getPersonsWithoutConnectedUser(
    searchParams: AsyncOptions,
    token: string
  ): Promise<PersonGetListResponse | null> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + 'person/list');
    url = appendSearchParams(url, searchParams);
    url.searchParams.append('withoutConnectedUser', 'true');

    try {
      const response = await fetch(String(url), {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...authHeader(token)
        }
      });
      const data = await response.json();
      if (response.statusText === 'OK' || response.status === 200) {
        return data;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  public async downloadFile(
    token: string
  ): Promise<Blob | string | IError> {
    let url = new URL(Config.MAIN_SERVICE_ENDPOINT + `person/export/without-property`);

    return fetch(url.toString(), {
      method: 'GET',
      headers: {
        ...authHeader(token)
      }
    }).then(res => res.blob())
  }
}

export const PersonApi = new API();

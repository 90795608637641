import React, { FC, useContext, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";

/* components */
import Filters from "components/page/Filters";
import SelectMultiSearch from "components/UI/controls/SelectMultiSearch";
import Checkbox from "components/UI/controls/Checkbox";
import PopupTagGroup from "components/modals/PopupTagGroup";
import PopupTag from "components/modals/PopupTag";

/* controllers */
import { getTaxonomiesByTypeAction } from "controllers/taxonomy/actions";

/* constants */
import { PageEnum } from "config/constants";
import { loadEnterprisesOptions, loadTagsOptions } from "config/constants/select-options";
import { ExportMainEntityEnum, TaxonomyTypeSlug, UserTypeEnum } from "@ternala/voltore-types/lib/constants";

/* types */
import { IStore } from "controllers/store";
import { ITaxonomyState } from "controllers/taxonomy/models";
import { PersonsGetListFiltersExtended } from "controllers/person/models";
import { IFiltrationTag, OptionType } from "models";
import { arrayDifferences, mapEnum } from "utils/helper-functions";
import { TagApi } from "controllers/tag/transport/tag.api";
import TagsContext, { TagTypeEnum } from "context/Tags";
import { getAccessTokenUtil } from "utils";
import ExportTab from "../../../components/page/Tabs/Export/ExportTab";
import { PersonGetListRequest } from "@ternala/voltore-types";

interface Props {
  taxonomyState: ITaxonomyState;
  appliedFilters?: PersonsGetListFiltersExtended;
  onFiltersUpdated: (filters: {
    persons: PersonsGetListFiltersExtended;
  }) => void;
  onClose: () => void;
  userType?: UserTypeEnum
}

enum FiltersTab {
  PEOPLE = 'People',
  TAG = 'Tag',
}

enum AdditionalTabsEnum {
  EXPORT = 'Export',
}

const PeoplePageFilters: FC<Props> = (props) => {
  const {
    taxonomyState: { taxonomies },
    appliedFilters,
    onFiltersUpdated,
    onClose,
    userType
  } = props;

  const dispatch = useDispatch();

  // Context (filters)
  const tagsFilters = useContext(TagsContext);

  const [activeTab, setActiveTab] = useState<FiltersTab | AdditionalTabsEnum>(
    FiltersTab.PEOPLE,
  );
  const [involvementTypes, setInvolvementTypes] = useState<
    OptionType[] | undefined
  >(appliedFilters?.involvementTypeFilter || []);
  const [enterprises, setEnterprises] = useState<OptionType[] | undefined>(
    appliedFilters?.enterpriseFilter || [],
  );
  const [isPrincipal, setIsPrincipal] = useState<boolean>(
    appliedFilters?.owner || false,
  );
  const [isDecisionMaker, setIsDecisionMaker] = useState<boolean>(
    appliedFilters?.decisionMaker || false,
  );

  /* TAG tab */
  const [selectedTags, setSelectedTags] = useState<OptionType[] | undefined>(
    tagsFilters?.tags?.map((tag) => ({
      label: tag?.fullTitle,
      value: tag?.id,
    })) || [],
  );

  const [excludedTags, setExcludedTags] = useState<OptionType[] | undefined>(
    tagsFilters?.excludedTags?.map((tag) => ({
      label: tag?.fullTitle,
      value: tag?.id
    })) || []);

  useEffect(() => {
    setSelectedTags(
      tagsFilters?.tags?.map((tag) => ({
        label: tag?.fullTitle,
        value: tag?.id,
      })) || [],
    );
  }, [tagsFilters?.tags]);

  useEffect(() => {
    setExcludedTags(
      tagsFilters?.excludedTags?.map((tag) => ({
        label: tag?.fullTitle,
        value: tag?.id
      })) || []
    );
  }, [tagsFilters?.excludedTags]);

  const [isTagCreationOpen, setIsTagCreationOpen] = useState<boolean>(false);
  const [isTagCategoryCreationOpen, setIsTagCategoryCreationOpen] =
    useState<boolean>(false);

  // custom hook for getting previous value
  function usePrevious(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  // the App where the hook is used
  function CheckTaxonomy() {
    const prevTaxonomy = usePrevious(taxonomies);
    const { oldArray, newArray } = arrayDifferences(prevTaxonomy, taxonomies);
    return !!(oldArray.length || newArray.length);
  }

  if (CheckTaxonomy())
    useEffect(() => {
      dispatch(
        getTaxonomiesByTypeAction.request({
          type: TaxonomyTypeSlug.involvementType,
        }),
      );
    }, []);

  const clearAllFilters = () => {
    setInvolvementTypes([]);
    setEnterprises([]);
    setIsPrincipal(false);
    setIsDecisionMaker(false);
    setSelectedTags([]);
    tagsFilters.removeTags();
    tagsFilters.removeTags(() => {}, TagTypeEnum.ExcludedTags);
    onFiltersUpdated({ persons: {} });
  };

  const prepareFilters = () => {
    const formatTags: IFiltrationTag[] | undefined = selectedTags?.map((tag) => {
      const newTag: IFiltrationTag = {
        fullTitle: String(tag.label),
        id: Number(tag.value),
      };
      return newTag;
    });
    const excludedTagsFormat: IFiltrationTag[] | undefined = excludedTags?.map((tag) => {
      const newTag: IFiltrationTag = {
        fullTitle: String(tag.label),
        id: Number(tag.value)
      };
      return newTag;
    });

    if (formatTags !== tagsFilters?.tags) tagsFilters.setTags(formatTags || []);
    if (excludedTagsFormat !== tagsFilters?.excludedTags)
      tagsFilters.setTags(excludedTagsFormat as IFiltrationTag[], TagTypeEnum.ExcludedTags);

    const searchParams: PersonGetListRequest = {};
    if (involvementTypes?.length) {
      searchParams[TaxonomyTypeSlug.involvementType] = involvementTypes.map(
        (filter) => Number(filter.value),
      );
    }
    if (tagsFilters?.tags?.length || selectedTags?.length) {
      searchParams.selectedTags = [
        ...new Set([
          ...(tagsFilters?.tags || []).map(({ id }) => id),
          ...(selectedTags || []).map(({ value }) => value),
        ]),
      ].filter(Boolean) as number[];
    }
    if (excludedTags?.length) {
      searchParams.unselectedTags = [
        ...new Set([
          ...(excludedTags || []).map(({ value }) => value),
        ]),
      ].filter(Boolean) as number[];
    }
    if (enterprises?.length) {
      searchParams.enterprise = enterprises.map((filter) =>
        Number(filter.value),
      );
    }
    if (isPrincipal) {
      searchParams.owner = true;
    }
    if (isDecisionMaker) {
      searchParams.decisionMaker = true;
    }

    return {
      persons: searchParams,
    };
  };

  const applyFilters = () => {
    const formatTags: IFiltrationTag[] | undefined = selectedTags?.map((tag) => {
      const newTag: IFiltrationTag = {
        fullTitle: String(tag.label),
        id: Number(tag.value),
      };
      return newTag;
    });
    const excludedTagsFormat: IFiltrationTag[] | undefined = excludedTags?.map((tag) => {
      const newTag: IFiltrationTag = {
        fullTitle: String(tag.label),
        id: Number(tag.value)
      };
      return newTag;
    });

    if (formatTags !== tagsFilters?.tags) tagsFilters.setTags(formatTags || []);
    if (excludedTagsFormat !== tagsFilters?.excludedTags)
      tagsFilters.setTags(excludedTagsFormat as IFiltrationTag[], TagTypeEnum.ExcludedTags);

    const appliedFilters = {
      persons: {
        ...((tagsFilters?.tags?.length || selectedTags) && {
          selectedTags: [
            ...new Set([
              ...(tagsFilters?.tags || []).map(({ id }) => id),
              ...(selectedTags || []).map(({ value }) => value),
            ]),
          ],
        }),
        ...((excludedTags) && {
          unselectedTags: excludedTags.map(({ value }) => value)
        }),
      } as PersonsGetListFiltersExtended,
    };
    if (involvementTypes?.length) {
      appliedFilters.persons.involvementTypeFilter = involvementTypes;
    }
    if (enterprises?.length) {
      appliedFilters.persons.enterpriseFilter = enterprises;
    }
    if (isPrincipal) appliedFilters.persons.owner = true;
    if (isDecisionMaker) appliedFilters.persons.decisionMaker = true;

    console.log('appliedFilters: ', appliedFilters);

    onFiltersUpdated(appliedFilters);
    onClose();
  };

  const peopleFiltersTabs = mapEnum(FiltersTab, (tab: FiltersTab) => ({
    label: tab,
    onClick: () => setActiveTab(tab),
  }));

  const rightTabs = mapEnum(AdditionalTabsEnum, (tab: FiltersTab) => ({
    label: tab,
    onClick: () => setActiveTab(tab),
  }));

  return (
    <Filters
      page={PageEnum.PEOPLE}
      tabs={peopleFiltersTabs}
      rightTabs={userType === UserTypeEnum.SA ? rightTabs : undefined}
      activeTab={activeTab}
      onClear={clearAllFilters}
      onApply={applyFilters}
      onClose={onClose}>
      {activeTab === FiltersTab.PEOPLE && (
        <>
          <SelectMultiSearch
            label="position or role"
            selectedOptions={involvementTypes}
            options={taxonomies?.[TaxonomyTypeSlug.involvementType]?.map(
              (taxonomy) => ({
                label: taxonomy.title,
                value: taxonomy.id,
              }),
            )}
            onChange={(data) => setInvolvementTypes(data)}
            width="100%"
            darkTheme
          />

          <Checkbox
            label="Principal"
            checked={isPrincipal}
            onChange={() => setIsPrincipal(!isPrincipal)}
            darkTheme
          />

          <Checkbox
            label="Decision Maker"
            checked={isDecisionMaker}
            onChange={() => setIsDecisionMaker(!isDecisionMaker)}
            darkTheme
          />

          <SelectMultiSearch
            label="enterprise"
            selectedOptions={enterprises}
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadEnterprisesOptions(searchParams, token);
              }
              return [];
            }}
            onChange={(data) => setEnterprises(data)}
            width="100%"
            darkTheme
          />
        </>
      )}
      {activeTab === FiltersTab.TAG && (
        <>
          <SelectMultiSearch
            label="tag(s)"
            asyncOptions={async (searchParams) => {
              const token = await getAccessTokenUtil(dispatch);
              if (token) {
                return await loadTagsOptions(searchParams, token);
              }
              return [];
            }}
            selectedOptions={selectedTags}
            onChange={(options) => options && setSelectedTags(options)}
            creation={{
              label: 'Create new tag',
              onClick: () => {
                setIsTagCreationOpen(true);
              },
            }}
            width="100%"
            darkTheme
          />
          <SelectMultiSearch
              label="exclude tag(s)"
              selectedOptions={excludedTags}
              asyncOptions={async (searchParams) => {
                const token = await getAccessTokenUtil(dispatch);
                if (token) {
                  return await loadTagsOptions(searchParams, token);
                }
                return [];
              }}
              creation={{
                label: 'Create new tag',
                onClick: () => {
                  setIsTagCreationOpen(true);
                },
              }}
              onChange={(data) => setExcludedTags(data)}
              width="100%"
              darkTheme
          />
          {isTagCreationOpen ? (
            <PopupTag
              setGroupCreation={setIsTagCategoryCreationOpen}
              onClose={() => {
                setIsTagCreationOpen(false);
              }}
              onCreate={async function (data) {
                const token = await getAccessTokenUtil(dispatch);
                if (token) return await TagApi.createTag(data, token);
              }}
            />
          ) : (
            ''
          )}
          {isTagCategoryCreationOpen ? (
            <PopupTagGroup
              onClose={() => {
                setIsTagCategoryCreationOpen(false);
              }}
              onCreate={async function (data) {
                const token = await getAccessTokenUtil(dispatch);
                if (token) return await TagApi.createTagCategory(data, token);
              }}
            />
          ) : (
            ''
          )}
        </>
      )}
      {activeTab === AdditionalTabsEnum.EXPORT && (
        <ExportTab
          entityType={ExportMainEntityEnum.person}
          getFilters={() => prepareFilters()}
        />
      )}
    </Filters>
  );
};

export default connect((store: IStore) => ({
  taxonomyState: store.taxonomy,
  userType: store.auth.account?.type.slug,
}))(PeoplePageFilters);

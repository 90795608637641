import React, { FC, useContext, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { omit } from 'lodash';
import { Helmet } from 'react-helmet';
import { RouterState } from 'connected-react-router';
import { PersonGetListRequest } from '@ternala/voltore-types';
import {
  personSortFields,
  sortType,
  TaxonomyTypeSlug,
} from '@ternala/voltore-types/lib/constants';

/* view components */
import DataList from 'components/page/DataList';
import Filters from 'pages/People/Filters';
import { personModalKey } from 'components/modals/PersonModal';

/* controllers */
import { getPersonsAction } from 'controllers/person/actions';

/* constants */
import { LOADED_ITEMS_LIMIT } from 'config';
import { PageEnum } from 'config/constants';
import { personsSortOptions, Sorting } from 'config/constants/sorting';


/* types */
import { IStore } from 'controllers/store';
import {
  IPersonState,
  PersonsGetListFiltersExtended,
} from 'controllers/person/models';
import { ItemsLoadType, OptionType, sortingTypeKeys } from 'models';
import TagsContext, { TagTypeEnum } from "context/Tags";
import { addShowedElement } from 'controllers/showElement/actions';
import { ShowElementEnum } from 'controllers/showElement/models.d';
import { uuid, isArrayEquals } from 'utils';
import { personToDataListItem } from './utils';

interface Props {
  routerState: RouterState;
  personsState: IPersonState;
}

const PeoplePage: FC<Props> = (props) => {
  const {
    routerState: {
      location: { pathname },
    },
    personsState: {
      persons,
      newPersons,
      personData,
      count,
      isAll,
      storedSearchParams,
    },
    children,
  } = props;

  const dispatch = useDispatch();
  const tagsFilters = useContext(TagsContext);

  const [filtersOpened, setFiltersOpened] = useState<boolean>(false);
  const [filters, setFilters] = useState<{
    persons: PersonsGetListFiltersExtended;
  }>();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const [sortValue, setSortValue] = useState<OptionType>(personsSortOptions[0]);
  const [sortingType, setSortingType] = useState<sortType>(sortType.asc);
  const [sortField, setSortField] = useState<personSortFields | undefined>(
    personSortFields.lastName,
  );

  useEffect(() => {
    setIsLoading(true);
    loadPersons('start', () => setIsLoading(false));
  }, [sortingType, sortField, filters]);

  useEffect(() => {
    if (searchQuery === '') {
      setIsLoading(true);
      loadPersons('start', () => setIsLoading(false));
    }
  }, [searchQuery]);

  useEffect(() => {
    setIsLoading(true);
    loadPersons('start', () => setIsLoading(false));
    return () => {
      tagsFilters.removeTags();
      tagsFilters.removeTags(() => {}, TagTypeEnum.ExcludedTags);
    };
  }, []);

  useEffect(() => {
    if (!tagsFilters?.tags?.length) return;
    const ids = tagsFilters?.tags?.map((tag) => tag.id);
    if(!filters?.persons?.selectedTags || !isArrayEquals(ids, filters?.persons?.selectedTags)) {
      setFilters({
        persons: {
          ...filters?.persons,
          selectedTags: ids,
        },
      });
    }
  }, [tagsFilters?.tags]);

  const loadPersons = (
    loadType: ItemsLoadType = 'start',
    callback?: Function,
  ) => {
    const searchParams: PersonGetListRequest = {
      limit: LOADED_ITEMS_LIMIT,
      offset: loadType === 'more' ? persons?.length : 0,
      query: searchQuery,
      sortType: sortingType,
      sortField: sortField,
    };

    const appliedFilters = filters?.persons;

    if (appliedFilters?.involvementTypeFilter?.length) {
      searchParams[TaxonomyTypeSlug.involvementType] =
        appliedFilters.involvementTypeFilter.map((filter) =>
          Number(filter.value),
        );
    }
    if (appliedFilters?.selectedTags?.length) {
      searchParams.selectedTags = filters?.persons?.selectedTags;
    }
    if (appliedFilters?.unselectedTags) {
      searchParams.unselectedTags = filters?.persons?.unselectedTags;
    }
    if (appliedFilters?.enterpriseFilter?.length) {
      searchParams.enterprise = appliedFilters.enterpriseFilter.map((filter) =>
        Number(filter.value),
      );
    }
    if (appliedFilters?.owner) {
      searchParams.owner = true;
    }
    if (appliedFilters?.decisionMaker) {
      searchParams.decisionMaker = true;
    }
    if (
      JSON.stringify(omit(storedSearchParams, ['limit', 'offset'])) !==
      JSON.stringify(omit(searchParams, ['limit', 'offset']))
    ) {
      searchParams.offset = 0;
    }

    dispatch(getPersonsAction.request({ ...searchParams, callback }));
  };

  const onSort = (sortOption: OptionType) => {
    const sorting = Sorting[sortOption.value as sortingTypeKeys];
    setSortValue(sortOption);
    setSortField(sorting.sortField as personSortFields);
    setSortingType(sorting.sortType);
  };

  const peopleListItems = persons
    ?.map((id) => personData[id])
    ?.map(personToDataListItem);

  const newPeopleListItems = newPersons
    ?.map((id) => personData[id])
    ?.map(personToDataListItem);

  return (
    <>
      <div className="people-page">
        <Helmet>
          <title>
            People | Voltore{' '}
            {process.env.REACT_APP_COMPANY_NAME
              ? `| ${process.env.REACT_APP_COMPANY_NAME}`
              : ''}
          </title>
          <meta name="description" content="Voltore application" />
        </Helmet>
        <div className="page-layout__content">
          <DataList
            setIsLoading={setIsLoading}
            itemsLoading={isLoading}
            items={peopleListItems}
            newItems={newPeopleListItems || []}
            itemsCount={count}
            allItemsLoaded={isAll}
            activeTab="info"
            pageTitle={PageEnum.PEOPLE}
            singleItem="person"
            onLoadMore={loadPersons}
            onSort={onSort}
            sortValue={sortValue}
            sortOptions={personsSortOptions}
            checkSuffixByActive={false}
            listControls={{
              searchPlaceholder:
                'Search by Person’s Name, Nickname, Email, Phone #, Home Mailing Address',
              searchQuery,
              clearSearchQuery: () => setSearchQuery(''),
              onSearch: (query) => setSearchQuery(query),
              onAddNew: () => {
                dispatch(
                  addShowedElement({
                    key: personModalKey,
                    type: ShowElementEnum.modal,
                    id: uuid(),
                    props: {},
                  }),
                );
              },
            }}
            Filters={{
              isOpen: filtersOpened,
              toggle: () => setFiltersOpened(!filtersOpened),
              applied: filters,
              resetFilter: () => {
                setFilters({ persons: {} })
              },
              onClear: () => {
                setFilters({ persons: {} })
              },
            }}
          />

          <div className="item-info">
            {pathname === `/${PageEnum.PEOPLE}` && (
              <div className="empty-container-wrapper">
                <div className="empty-container" />
              </div>
            )}

            {children}
          </div>

          {filtersOpened && (
            <Filters
              appliedFilters={filters?.persons}
              onFiltersUpdated={(filters) => {
                setFilters(filters)
              }}
              onClose={() => setFiltersOpened(false)}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default connect((store: IStore) => ({
  routerState: store.router,
  personsState: store.person,
}))(PeoplePage);
